<template>
  <v-container>
    <AppLoadingSpinner v-model="isLoading" />
    <v-row>
      <v-col cols="3" sm="4">
        <v-card class="fixed-card-left">
          <v-card-title>
            <span>量表文档与设置</span>
            <v-spacer></v-spacer>
            <v-scroll-x-transition>
              <v-text-field
                v-if="isShowSearchBar"
                flat
                dense
                hide-details
                v-model="lbSearchText"
              ></v-text-field>
            </v-scroll-x-transition>
            <v-btn icon color="primary" @click="toggleLbSearchBar">
              <v-icon v-if="isShowSearchBar">mdi-close</v-icon>
              <v-icon v-else>mdi-magnify</v-icon>
            </v-btn>
          </v-card-title>
          <div></div>
          <v-divider></v-divider>
          <v-treeview
            class="fixed-tree-left py-3 text-body-2"
            transition
            :active.sync="lbInfoSelected"
            :search="lbSearchText"
            :items="lbInfoList"
            item-key="id"
            item-text="name"
            activatable
            open-on-click
          >
            <template v-slot:prepend="{ item, open }">
              <v-icon v-if="!item.icon" :key="item.id">
                {{ open ? "mdi-folder-open" : "mdi-folder" }}
              </v-icon>
              <v-icon v-else :key="item.id">
                {{ item.icon }}
              </v-icon>
            </template>
          </v-treeview>
        </v-card>
      </v-col>
      <v-col cols="9" sm="8">
        <v-card v-if="isShowLbDetailPanel">
          <v-tabs show-arrows>
            <v-tab>量表文档</v-tab>
            <v-tab v-if="isAdminRole">基本设置</v-tab>
            <v-tab v-if="isAdminRole">预警设置</v-tab>
            <v-tab-item>
              <v-card flat class="fixed-card-right py-4 px-6">
                <v-card-text v-html="lbDetails.htmlDoc"></v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item v-if="isAdminRole">
              <AdminLbManageInfo
                :lb-guid="selectedLbGuid"
                :lb-details="lbDetails"
              />
            </v-tab-item>
            <v-tab-item v-if="isAdminRole">
              <AdminLbManageAlert :lb-guid="selectedLbGuid" />
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </v-container>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AdminLbManageInfo from "@/components/admin/AdminLbManageInfo.vue";
import AdminLbManageAlert from "@/components/admin/AdminLbManageAlert.vue";
import _ from "lodash";
import { mapGetters } from "vuex";
import { getLbListWithCategory, getLbDetails } from "@/api/lb";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AdminLbManageInfo,
    AdminLbManageAlert
  },

  data() {
    return {
      isLoading: false,
      isShowLbDetailPanel: false,
      lbInfoList: [],
      lbInfoSelected: [],
      lbDetails: {},
      lbSearchText: "",
      isShowSearchBar: false,
      errorMsg: ""
    };
  },

  watch: {
    lbInfoSelected() {
      this.errorMsg = "";
      this.lbDetails = {};
      this.getLbDocFromActiveId();
    }
  },

  computed: {
    ...mapGetters({
      userGuid: "user/userGuid",
      roles: "user/roles"
    }),
    isAdminRole() {
      return this.roles.includes("admin");
    },
    selectedLbGuid() {
      if (this.lbInfoSelected && this.lbInfoSelected.length) {
        return this.lbInfoSelected[0];
      }
      return "";
    }
  },

  methods: {
    toggleLbSearchBar() {
      if (this.isShowSearchBar) {
        this.lbSearchText = "";
        this.isShowSearchBar = false;
      } else {
        this.isShowSearchBar = true;
      }
    },
    async getAllLbList() {
      try {
        this.isLoading = true;
        let lbList = await getLbListWithCategory(this.userGuid);
        this.lbInfoList = _.chain(lbList)
          .groupBy("category.displayName")
          .map((lb, lbCatName) => ({
            id: lbCatName,
            name: lbCatName,
            children: lb.map(l => ({
              id: l.lbGuid,
              name: l.dispName,
              icon: "mdi-file-document-outline"
            }))
          }))
          .value();
      } catch (err) {
        this.errorMsg = err;
      }
      this.isLoading = false;
    },
    async getLbDocFromActiveId() {
      this.isShowLbDetailPanel = false;
      if (this.selectedLbGuid) {
        try {
          this.isLoading = true;
          this.lbDetails = await getLbDetails(this.selectedLbGuid);
          this.isShowLbDetailPanel = true;
        } catch (err) {
          this.errorMsg = err;
        }
        this.isLoading = false;
      }
    }
  },

  created() {
    this.getAllLbList();
  }
};
</script>

<style lang="scss" scoped>
.fixed-card-left {
  overflow: auto;
  .fixed-tree-left {
    height: calc(100vh - 240px);
    min-width: 250px;
  }
}
.fixed-card-right {
  height: calc(100vh - 220px);
  overflow: auto;
}
</style>
